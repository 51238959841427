import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Layout from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { Footer } from '../components/Footer';
import { Branding } from '../components/Branding';

const IndexPage = () => {
  const [destination, setDestination] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const url = params.get('returnTo');
      const dest = `${window.location.origin}${url}`;
      setDestination(dest);
      window.location.assign(dest);
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="Redirecting you..."
        siteUrl="https://bloggingfordevs.com"
        description="Redirecting you"
        pathname="callback/"
        noIndex
      />
      <div
        style={{
          maxWidth: '500px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Branding to="/">Blogging for Devs</Branding>
        <Typography variant="h4" component="h1" gutterBottom>
          One second...
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          We're redirecting you to the page you were on before.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          If that doesn't work,{' '}
          <u>
            <a href={destination}>click here to continue</a>
          </u>
          .
        </Typography>
      </div>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
